<template>
    <div>
        <el-form :inline="true">
            <el-form-item label="语言">
                <el-select v-model="langs" clearable placeholder="语言">
                    <el-option v-for="item in langOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button @click="handleStart()" type="primary">开始处理</el-button>
            </el-form-item>
            <el-form-item>
                <span>
                    今日客诉数量 {{ reportCount.totalToday }}，今日已处理数量 {{ reportCount.finishToday }}，当前待处理总量
                    {{ reportCount.unFinishTotal }}，今日反馈且未处理量 {{ reportCount.unFinishToday }}
                </span>
            </el-form-item>
            <el-form-item>
                <el-tag v-if="reportCount.online" type="success">已上线</el-tag>
                <el-tag v-else type="danger">已离线</el-tag>
            </el-form-item>
        </el-form>

        <div class="content" v-if="chatVisibility">
            <div class="user-info">
                <h3 class="user-info-title">
                    【客诉用户信息】
                </h3>
                <div class="user-info-avatar">
                    <img :src="!user.avatar ? require('@/assets/img/default_avatar.png') : user.avatar" alt=""
                        style="width: 12vw;height: 12vw;">
                </div>
                <div class="user-info-text">
                    <div> <label>uid: &nbsp;</label> <span>{{ user.uid }}</span> </div>
                    <div> <label>昵称：</label> <span>{{ user.nickName }}</span> </div>
                    <div> <label>注册时间：</label> <span>{{ user.createTime }}</span> </div>
                </div>
            </div>
            <div class="chat">
                <div class="chat-warning">
                    请注意：你的沟通代表官方，全部内容将被监控并传输至<br />
                    你的直属leader，请谨慎发言切勿违反平台制度及法律
                </div>
                <div class="chat-content">
                    <div v-for="(item, index) in recordContent" :key="index">
                        <!-- 对方 -->
                        <div class="word" v-if="item.msgtype === 0">
                            <img :src="!user.avatar ? require('@/assets/img/default_avatar.png') : user.avatar">
                            <div class="info">
                                <p class="time">{{ item.atime }}</p>
                                <div class="info-content">{{ item.content }}</div>
                            </div>
                        </div>
                        <!-- 我的 -->
                        <div class="word-my" v-else>
                            <div class="info">
                                <p class="time">{{ item.atime }}</p>
                                <div class="info-content">{{ item.content }}</div>
                            </div>
                            <div>
                                <img src="@/assets/img/server_avatar.png">
                                <p class="name">{{ item.csNickname }}</p>
                            </div>
                        </div>
                    </div>
                    <p v-if="showTips" class="tips">————当前用户超过60s没有回复，您可以考虑主动完结此客诉————</p>
                </div>
                <div class="chat-reply-box">
                    <el-input type="textarea" v-model="replyMessage" :autosize="{ minRows: 2, maxRows: 3 }" resize="none"
                        required @keydown.ctrl.enter.native="sendMessage()" placeholder="ctrl+enter发送"></el-input>
                    <div class="submit-box">
                        <el-tooltip :disabled="this.replyMessage.length !== 0" content="不能发送空白消息" placement="top-end"
                            effect="light">
                            <el-button :disabled="this.replyMessage.length === 0" @click="sendMessage"
                                size="small">发送</el-button>
                        </el-tooltip>
                    </div>

                </div>
            </div>
            <div class="submit-btn">
                <el-button class="close" size="small" :disabled="btnStatus === 1"
                    @click="handleCloseChat">完成本次客诉</el-button>
                <el-tooltip effect="light" :disabled="btnStatus === 1" content=" 请先完成当前作业！" placement="bottom">
                    <el-button class="submit" size="small" :disabled="btnStatus === 0" @click="handleSubmit">提交</el-button>
                </el-tooltip>
            </div>
        </div>

        <div class="finish-btn" v-if="reportCount.online">
            <el-tooltip effect="light" :disabled="!(user.uid !== '' && btnStatus !== 1)" content="请先完成当前作业！"
                placement="top">
                <div>
                    <el-button :disabled="user.uid !== '' && btnStatus !== 1" class="finish" size="small"
                        @click="handleFinishChat">结束作业</el-button>
                </div>
            </el-tooltip>
        </div>

    </div>
</template>
<script>
import { langOpt } from "@/views/common/commonOpt";

export default {
    data() {
        return {
            chatVisibility: false,
            langs: 'all',
            reportCount: {
                totalToday: 0,
                finishToday: 0,
                unFinishTotal: 0,
                unFinishToday: 0,
                online: ''
            },
            user: {
                avatar: '',
                uid: '',
                nickName: '',
                createTime: '',
            },
            langOpt: langOpt,
            recordContent: [],
            replyMessage: '',
            headTimer: '',
            chatTimer: '',
            // sendMessageTimer: '',
            btnStatus: 0, //0-初始状态 1-关闭当前任务 2-结束作业 
            recentChatId: '',
            recentReplyTime: '',
            showTips: false
        }
    },
    mounted() {
        this.getHeaderData()
    },
    updated() {
        // 聊天定位到底部
        let ele = document.querySelector('.chat-content')
        if (ele) {
            ele.scrollTop = ele.scrollHeight;
        }
    },
    methods: {
        // 获取头部数据
        getHeaderData() {
            this.$http({
                url: this.$http.adornUrl('/cs/system/headData'),
                method: 'post',
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    const { totalToday, finishToday, unFinishTotal, unFinishToday, online } = data.result
                    this.reportCount.online = online
                    this.reportCount.totalToday = !totalToday ? 0 : totalToday
                    this.reportCount.finishToday = !finishToday ? 0 : finishToday
                    this.reportCount.unFinishToday = !unFinishToday ? 0 : unFinishToday
                    this.reportCount.unFinishTotal = !unFinishTotal ? 0 : unFinishTotal
                } else {
                    this.$message.error(data.msg)
                }
            })
        },

        getUserInfo() {
            this.$http({
                url: this.$http.adornUrl(`/cs/system/userInfo?uid=${this.user.uid}`),
                method: 'post',
            }).then(({ data }) => {
                if (data.code !== 0) {
                    this.$message.error(data.msg)
                }
            })
        },

        getChatHistory() {
            this.$http({
                url: this.$http.adornUrl(`/cs/system/chatHistory?uid=${this.user.uid}&chatId=${this.recentChatId}`),
                method: 'post',
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    if (data.result.length > 0) {
                        this.recordContent = this.recordContent.concat(data.result)
                        this.recentChatId = data.result[data.result.length - 1].id
                        this.recentReplyTime = data.result[data.result.length - 1].msgtype === 1 ? data.result[data.result.length - 1].atime : null;
                        // console.log('this.recentReplyTime', this.recentReplyTime)
                    }
                    if (this.recentReplyTime !== null) {
                        let currentTime = new Date();
                        let lastReplyTime = new Date(this.recentReplyTime)
                        const timeDiff = currentTime.getTime() - lastReplyTime.getTime();

                        // 将时间差转换为分钟
                        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
                        // console.log('minutesDiff', minutesDiff)
                        if (minutesDiff > 1) {
                            this.showTips = true
                        } else {
                            this.showTips = false
                        }
                    } else {
                        this.showTips = false
                    }
                } else {
                    this.$message.error(data.msg)
                }
            })
        },

        handleStart() {
            if (this.chatTimer) {
                clearInterval(this.chatTimer)
            }
            if (this.headTimer) {
                clearInterval(this.headTimer)
            }
            this.btnStatus = 0
            this.recentChatId = ''
            this.recentReplyTime = ''
            this.user.avatar = ''
            this.user.uid = ''
            this.user.nickName = ''
            this.user.createTime = ''
            this.$http({
                url: this.$http.adornUrl(`/cs/system/start?lang=${this.langs}`),
                method: 'post',
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.$nextTick(() => {
                        this.getHeaderData()
                    })
                    if (data.result.uid) {
                        this.user = data.result
                        this.chatVisibility = true
                        this.getUserInfo()
                        this.getChatHistory()

                        this.$nextTick(() => {
                            // 10秒刷新一次聊天记录
                            this.chatTimer = setInterval(() => {
                                this.getChatHistory()
                            }, 10000);

                            // 60秒更新一次顶部数据
                            this.headTimer = setInterval(() => {
                                this.getHeaderData()
                            }, 60000);
                        })
                    } else {
                        this.chatVisibility = false
                        this.$message.warning('当前没有新的用户反馈!')
                    }

                } else {
                    this.$message.error(data.msg)
                }
            })
        },

        sendMessage() {
            this.$http({
                url: this.$http.adornUrl(`/cs/system/send?content=${this.replyMessage}&uid=${this.user.uid}`),
                method: 'post',
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.replyMessage = ''
                    this.getChatHistory()

                    // if (this.sendMessageTimer) {
                    //     clearTimeout(this.sendMessageTimer)
                    // }
                    // this.sendMessageTimer = setTimeout(() => {
                    //     this.getChatHistory()
                    // }, 2000);
                    // this.$nextTick(() => {
                    //     this.getChatHistory()
                    // })
                } else {
                    this.$message.error(data.msg)
                }
            })
        },

        handleFinishChat() {
            if (this.headTimer) {
                clearInterval(this.headTimer)
            }
            if (this.chatTimer) {
                clearInterval(this.chatTimer)
            }
            if (this.user.uid) {
                this.$http({
                    url: this.$http.adornUrl(`/cs/system/commit?uid=${this.user.uid}&lang=${this.langs}`),
                    method: 'post',
                }).then(({ data }) => {
                    if (data.code !== 0) {
                        this.$message.error(data.msg)
                    }
                })
            }
            this.$http({
                url: this.$http.adornUrl(`/cs/system/goHome`),
                method: 'post',
            }).then(({ data }) => {
                if (data.code !== 0) {
                    this.$message.error(data.msg)
                }
            })
            this.chatVisibility = false
            this.reportCount.online = false
        },

        handleCloseChat() {
            if (this.chatTimer) {
                clearInterval(this.chatTimer)
            }
            this.btnStatus = 1
        },

        handleSubmit() {
            this.$http({
                url: this.$http.adornUrl(`/cs/system/commit?uid=${this.user.uid}&lang=${this.langs}`),
                method: 'post',
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    // if (this.btnStatus === 1) {
                    //     this.handleStart()
                    // }
                    this.$nextTick(() => {
                        this.handleStart()
                    })
                } else {
                    this.$message.error(data.msg)
                }
            })
        }
    },
    beforeDestroy() {
        if (this.headTimer) {
            clearInterval(this.headTimer)
        }
        if (this.chatTimer) {
            clearInterval(this.chatTimer)
        }
        // clearTimeout(this.sendMessageTimer)
    },
}
</script>
<style scoped lang="scss">
.content {
    display: flex;

    .user-info {
        display: flex;
        width: 15vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: 1px solid rgb(128, 128, 128, 0.6);

        &-avatar {
            margin: 3vh 0;
        }

        &-text {
            div {
                margin-bottom: 3vh;
            }

            label {
                display: inline-block;
                font-size: 14px;
                font-weight: 700;
                width: 6vw;
                text-align: right;
            }

            span {
                font-size: 14px;
            }
        }
    }

    .chat {
        width: 50vw;
        height: 50vh;
        margin: 0 2vw;

        &-warning {
            display: flex;
            width: 100%;
            height: 10vh;
            background-color: #F2F2F2;
            color: #009DD9;
            justify-content: center;
            align-items: center;
        }

        &-content {
            width: 100%;
            height: 35vh;
            padding: 10px;
            overflow-y: auto;
            border: 1px solid #F2F2F2;

            .word {
                display: flex;
                margin-bottom: 20px;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }

                .info {
                    width: 90%;
                    margin-left: 10px;

                    .time {
                        font-size: 12px;
                        color: rgba(51, 51, 51, 0.8);
                        margin: 0;
                        height: 20px;
                        line-height: 20px;
                        margin-top: -5px;
                    }

                    .info-content {
                        width: fit-content;
                        padding: 10px;
                        font-size: 14px;
                        background: #F5F5F5;
                        position: relative;
                        margin-top: 8px;
                        border-radius: 10px;
                    }

                    //小三角形
                    .info-content::before {
                        position: absolute;
                        left: -8px;
                        top: 8px;
                        content: '';
                        border-right: 10px solid #F5F5F5;
                        border-top: 8px solid transparent;
                        border-bottom: 8px solid transparent;
                    }
                }
            }

            .word-my {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 20px;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }

                .info {
                    width: 90%;
                    margin-left: 10px;
                    text-align: right;

                    .time {
                        font-size: 12px;
                        color: rgba(51, 51, 51, 0.8);
                        margin: 0;
                        height: 20px;
                        line-height: 20px;
                        margin-top: -5px;
                        margin-right: 10px;
                    }

                    .info-content {
                        max-width: 70%;
                        padding: 10px;
                        font-size: 14px;
                        float: right;
                        margin-right: 10px;
                        position: relative;
                        margin-top: 8px;
                        background: #A3C3F6;
                        text-align: left;
                        border-radius: 10px;
                    }

                    //小三角形
                    .info-content::after {
                        position: absolute;
                        right: -8px;
                        top: 8px;
                        content: '';
                        border-left: 10px solid #A3C3F6;
                        border-top: 8px solid transparent;
                        border-bottom: 8px solid transparent;
                    }
                }

                .name {
                    font-size: 12px;
                    color: rgba(51, 51, 51, 0.8);
                }
            }

            .tips {
                font-size: 12px;
                text-align: center;
                color: #00BFBF;
            }
        }

        &-content::-webkit-scrollbar {
            display: none;
        }

        &-reply-box {
            width: 100%;
            border: 1px solid #F2F2F2;

            .el-input {
                width: 100%;
            }

            ::v-deep(.el-textarea__inner) {
                border-radius: unset;
                border: unset;
            }

            .submit-box {
                width: 100%;
                height: 10vh;

                .el-button {
                    float: right;
                    margin-right: 2vw;
                    background-color: #009DD9;
                    color: #FFF;
                }
            }
        }
    }

    .submit-btn {
        display: flex;
        flex-direction: column;
        justify-content: end;

        .el-button {
            color: #fff;
        }

        .close {
            background-color: #00BFBF;
            margin-bottom: 2vh;
        }

        .submit {
            background-color: #EC808D;
            margin-bottom: 45vh;
        }

        .el-button+.el-button {
            margin-left: 0;
        }
    }
}

.finish-btn {
    position: absolute;
    bottom: 4vh;
    right: 4vw;

    .finish {
        margin-left: 1vw;
        color: #fff;
        background-color: #FACD91;
    }
}
</style>
